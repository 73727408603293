import { useEffect } from "react";
import { SiteToDuplicateOrCreateType } from "../components/site/siteSlice";
import {
  useLazyGetSitesListByClientQuery,
  useLazyPostDuplicateOrCreateSiteQuery
} from "../redux/api/site";
import { getConfigurationItemsFromPendingChanges } from "../lib/siteHelpers";

const usePostDuplicateOrCreateSiteHook = (
  siteToDuplicateOrCreate: SiteToDuplicateOrCreateType,
  pendingConfigurationChangesList: PendingChangesListType,
  clientId?: string
) => {
  const { generalSiteInformation, fromSiteId, selectedEnvironmentsList } =
    siteToDuplicateOrCreate;

  const configurationItems: ConfigurationItemType[] =
    getConfigurationItemsFromPendingChanges(pendingConfigurationChangesList);

  const siteToDuplicateOrCreateData: SubmitDuplicateOrCreateSiteBody = {
    site: generalSiteInformation,
    fromSiteId,
    configurationItems,
    environmentIds: selectedEnvironmentsList,
    sendDeployments: false
  };

  const [triggerPostDuplicateOrCreateSite, result] =
    useLazyPostDuplicateOrCreateSiteQuery();
  const [triggerFetchSiteListByClientId] = useLazyGetSitesListByClientQuery();

  const submitSiteToDuplicateOrCreateChanges = () => {
    triggerPostDuplicateOrCreateSite(siteToDuplicateOrCreateData);
  };

  useEffect(() => {
    if (result.isSuccess && !!result.data && clientId) {
      triggerFetchSiteListByClientId({ clientId });
    }
  }, [result.isSuccess, result.data, triggerFetchSiteListByClientId]);
  return {
    ...result,
    submitSiteToDuplicateOrCreateChanges
  };
};

export default usePostDuplicateOrCreateSiteHook;
