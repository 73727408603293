import { GetDeploymentsStatusResponse } from "../redux/api/site";

export const defaultEnvironmentTagNamesListPriority: {
  [x in string]: number;
} = {
  staging: 1,
  qa: 2,
  uat: 2,
  production: 3
};

export const getEnvTagNameToDisplay = (originalEnvName: EnvTagNamesType) => {
  switch (originalEnvName?.toLowerCase()) {
    case "staging":
      return "Stg";
    case "production":
      return "Prod";
    case "qa":
    case "uat":
      return originalEnvName;
  }
};

export const getInitialConfigurationValue = (
  inputDataType: ConfigurationItemDataType,
  validationRules: ValidationRulesType | null
) => {
  switch (inputDataType) {
    case "boolean":
    case "bool":
      return validationRules?.defaultValue || false;
    case "text":
    case "guid":
    case "json":
    case "jsonb":
    case "url":
    case "ordered_list":
    case "text[]":
    case "int[]":
    case "select":
      return validationRules?.defaultValue || "";
    case "int":
      return validationRules?.defaultValue || validationRules?.min || 0;
    case "multiselect":
      return validationRules?.defaultValue || [];
  }
};

export const createSiteConfigurationMenu = (
  inputData: SiteConfigurationMenuItem[],
  parentId: string | null = null
): SiteConfigurationMenuItem[] => {
  return inputData.reduce<SiteConfigurationMenuItem[]>(
    (acc, currentElement) => {
      if (parentId == currentElement.parentConfigId) {
        const newConfigItemMenu: SiteConfigurationMenuItem = {
          ...currentElement,
          children: []
        };
        const children: SiteConfigurationMenuItem[] =
          createSiteConfigurationMenu(
            inputData,
            currentElement.configurationId
          );
        newConfigItemMenu.children = children || [];
        acc.push(newConfigItemMenu);
      }
      return acc;
    },
    []
  );
};

export const getConfigurationTreeAndConfigurationMenu = (
  inputData: SiteConfigurationTypeResponse
) => {
  const allConfigurationsIncludingConfigurationType = inputData.reduce<
    SiteConfigurationMenuItem[]
  >((acc, el) => {
    const { configurations, configurationTypeId, displayOrder } = el;

    const formattedConfigs = configurations.map(
      (element: SiteConfigurationDetails) => {
        return {
          ...element,
          configurationTypeId,
          displayOrder
        };
      }
    ) as unknown as SiteConfigurationMenuItem[];

    acc.push(...formattedConfigs);
    return acc;
  }, []);

  // get all configurations nested correctly
  const formattedConfigsByParentId = createSiteConfigurationMenu(
    allConfigurationsIncludingConfigurationType
  ).sort((a, b) => {
    const displayOrderA = a.displayOrder;
    const displayOrderB = b.displayOrder;

    return displayOrderA - displayOrderB;
  });

  // group all sorted and nested configurations from the above by the main menu groupings, the nested groupings will resolve themselves
  const formattedConfigurationsByMainGroupings =
    formattedConfigsByParentId.reduce<{
      [x in string]: { configurations: SiteConfigurationMenuItem[] };
    }>((acc, el) => {
      const { configurationTypeId } = el;
      if (acc[configurationTypeId] !== undefined) {
        acc[configurationTypeId].configurations.push(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[configurationTypeId] = {
          configurations: [el]
        };
      }
      return acc;
    }, {});

  // get configurations groupings menu used for getting grouping name for nested configurations
  const allConfigurationsMenuTypes =
    inputData.reduce<SiteConfigurationMenuGroupings>((acc, el) => {
      const { configurationTypeName, configurationTypeId } = el;
      // eslint-disable-next-line no-param-reassign
      acc[configurationTypeId] = {
        configurationTypeName,
        configurationTypeId
      };
      return acc;
    }, {});

  return {
    allConfigurationsMenuTypes,
    formattedConfigurationsByMainGroupings
  };
};

export const getRequiredDuplicatesiteFields = (
  siteConfigurationTypes: DuplicateSiteConfigurationType[],
  requiredDuplicateSiteConfigIds: string[]
): {
  requiredDuplicateSiteConfigurations: ConfigurationItemType[];
  allSiteConfigurationsByConfigurationId: AllConfigurationsbyConfigId;
} => {
  const siteConfigurations = siteConfigurationTypes.reduce<
    DuplicateSiteConfigurationsDetails[]
  >((acc, el) => {
    acc.push(...el.configurations);
    return acc;
  }, []);

  const requiredDuplicateSiteConfigurations = siteConfigurations
    .reduce<ConfigurationItemType[]>((acc, el) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      acc.push(...el?.configurationItems);
      return acc;
    }, [])
    .filter((el) =>
      requiredDuplicateSiteConfigIds.includes(el.configurationItemId)
    )
    .sort((a, b) => {
      const displayOrderA = a.displayOrder;
      const displayOrderB = b.displayOrder;

      return displayOrderA - displayOrderB;
    });

  const allSiteConfigurationsByConfigurationId =
    siteConfigurations.reduce<AllConfigurationsbyConfigId>((acc, el) => {
      // eslint-disable-next-line no-param-reassign
      acc[el.configurationId] = el;
      return acc;
    }, {});

  return {
    requiredDuplicateSiteConfigurations,
    allSiteConfigurationsByConfigurationId
  };
};

export const getPendingChangeConfigurationId = (
  configurationItemId: string,
  sectionId: string
) => `${sectionId.slice(0, 8)}-${configurationItemId.slice(0, 8)}`;

export const formatGetSiteDeploymentStatusResponse = (
  siteDeploymentsStatusResponse: GetDeploymentsStatusResponse
): SiteDeploymentsStatusResponse => {
  const deploymentsList = siteDeploymentsStatusResponse
    .sort(
      (deployment1, deployment2) => deployment1.sequence - deployment2.sequence
    )
    .reduce<DeploymentsByEventType>((acc, el) => {
      const { eventType, sequence } = el;
      if (acc[eventType]) {
        acc[eventType].deployments.push(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[eventType] = {
          deployments: [el],
          displayOrder: sequence,
          isEventTypeComplete: false
        };
      }
      return acc;
    }, {});
  const deploymentsByEventType = Object.fromEntries(
    Object.entries(deploymentsList).map(([eventType, eventData]) => {
      const isEventTypeComplete = eventData.deployments.every(
        (deployment) => deployment.completed
      );
      const newEventData = { ...eventData, isEventTypeComplete };
      return [eventType, newEventData];
    })
  );
  const deploymentsEventsList = Object.keys(deploymentsByEventType).sort(
    (eventType1, eventType2) =>
      deploymentsByEventType[eventType1].displayOrder -
      deploymentsByEventType[eventType2].displayOrder
  );
  const isSiteCreationComplete = siteDeploymentsStatusResponse.every(
    (deployment) => deployment.completed
  );

  return {
    deploymentsByEventType,
    deploymentsEventsList,
    isSiteCreationComplete
  };
};

export const getConfigurationItemsFromPendingChanges = (
  pendingConfigurationChangesList: PendingChangesListType
): ConfigurationItemType[] => {
  return Object.values(pendingConfigurationChangesList).map(
    (el: PendingChangeType) => {
      const {
        configurationName,
        dataType,
        configurationItemId,
        configurationId,
        configurationTypeId,
        validationRules,
        currentConfigurationItemValues,
        uniquePerEnvironment,
        description,
        integrationName
      } = el;

      const configurationItem: ConfigurationItemType = {
        name: configurationName,
        integrationName,
        dataType,
        description,
        configurationId,
        configurationTypeId,
        configurationItemId,
        uniquePerEnvironment,
        validationRules,
        configurationValues: currentConfigurationItemValues
      };
      return configurationItem;
    }
  );
};

export const getEnvIdsListFromSelectedConfigurationItems = (
  configurationItems: ConfigurationItemType[]
): string[] => {
  const selectedEnvironmentsListFromConfigurationValues: string[] = [];
  configurationItems.forEach((configItem) => {
    const currentEnvIdList = configItem.configurationValues
      .map((configValue) => configValue.environmentIds)
      .reduce((acc, envId) => acc.concat(envId), []);
    currentEnvIdList.forEach((envId) => {
      if (!selectedEnvironmentsListFromConfigurationValues.includes(envId)) {
        selectedEnvironmentsListFromConfigurationValues.push(envId);
      }
    });
  });
  return selectedEnvironmentsListFromConfigurationValues;
};
