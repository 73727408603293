import { useSelector } from "react-redux";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";
import Loading from "../Loading";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

type CustomDrawerContentProps = {
  isDataLoading: boolean;
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  drawerContentData: CustomDrawerContentType[];
  drawerType: DrawerType;
};

const MainContainer = styled(List)(() => ({
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  height: "90%"
}));

function CustomDrawerContent(props: CustomDrawerContentProps) {
  const { onDrawerMenuBtnClick, drawerContentData, isDataLoading, drawerType } =
    props;
  const selectedDrawerData = useSelector(getSelectedDrawersData);

  if (isDataLoading) return <Loading numberOfRows={5} />;

  const onDrawerItemClickBtn = (
    name: string,
    salesforceId: string | null,
    id: string
  ) => {
    const selectedClientDrawerDetails =
      drawerType === "site" ? selectedDrawerData.client : undefined;
    onDrawerMenuBtnClick(
      {
        name,
        id,
        salesforceId
      },
      selectedClientDrawerDetails
    );
  };
  return (
    <MainContainer data-testid={`drawer-content-${drawerType}`}>
      {drawerContentData.map((drawer, index) => {
        const { name, salesforceId, id } = drawer;
        return (
          <ListItemButton
            data-testid={`${drawerType}-drawer-content-item`}
            key={`${name}-${id}${index}`}
            style={{
              inlineSize: 200
            }}
            onClick={() => onDrawerItemClickBtn(name, salesforceId, id)}
          >
            <ListItemText primary={name} />
          </ListItemButton>
        );
      })}
    </MainContainer>
  );
}

export default CustomDrawerContent;
